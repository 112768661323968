import { HTMLAttributes, ReactNode } from "react";
import "./_scss/ModalContent.scss";
import { useMediaQuery } from "../../Hook/UseMediaQuery";
import { BreakpointEnum } from "../../Enum/BreakpointEnum";

type ModalContentProps = {
  spacing?: {
    all?: boolean | number,
    vertical?: boolean | number,
    horizontal?: boolean | number,
    top?: boolean | number,
    right?: boolean | number,
    bottom?: boolean | number,
    left?: boolean | number,
  };
  scrollable?: boolean;
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>

export function ModalContent({ spacing, scrollable, children, className, style, ...otherProps }: ModalContentProps)
{
  const BLOCK_NAME = "ModalContent";
  const blockClasses = [ BLOCK_NAME, className ];
  if (scrollable) blockClasses.push(`${BLOCK_NAME}--Scrollable`);
  const isMedium = useMediaQuery({ minWidth: BreakpointEnum.MEDIUM });
  const spacingValue = isMedium ? 40 : 20;
  const getSpacingValue = (...values: Array<boolean | number | undefined>) => {
    const applySpacing = values.find(value => value != null);
    if (typeof applySpacing === "number") return applySpacing;
    return applySpacing ? spacingValue : 0;
  };
  
  return (
    <div
      className={blockClasses.join(" ")}
      style={
        Object.assign(
          {},
          {
            paddingTop: getSpacingValue(spacing?.top, spacing?.vertical, spacing?.all),
            paddingRight: getSpacingValue(spacing?.right, spacing?.horizontal, spacing?.all),
            paddingBottom: getSpacingValue(spacing?.bottom || spacing?.vertical, spacing?.all),
            paddingLeft: getSpacingValue(spacing?.left, spacing?.horizontal, spacing?.all)
          },
          style
        )
      }
      {...otherProps}
    >
      {children}
    </div>
  );
}
