import { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import "./_scss/Modal.scss";
import { PageContainer } from "../PageContainer/PageContainer";

interface ModalProps
{
  visible: boolean;
  setVisible: (visible: boolean) => void;
  children: ReactNode;
}

export function Modal({ visible, setVisible, children }: ModalProps)
{
  const BLOCK_NAME = "Modal";
  const blockClasses = [ BLOCK_NAME ];
  if (visible) blockClasses.push(`${BLOCK_NAME}--Visible`);
  
  // Prevent scrolling of main site when modal is open
  useEffect(() => {
    if (visible)
    {
      document.body.style.overflow = "hidden";
    }
    else
    {
      document.body.style.overflow = "initial";
    }
  }, [ visible ]);
  
  // Close modal when escape key is pressed
  useEffect(() => {
    if (!visible) return;
    
    const onKeyUp = (event: KeyboardEvent) => {
      if (event.key !== "Escape") return;
      setVisible(false);
    };
    window.addEventListener("keyup", onKeyUp);
    return () => window.removeEventListener("keyup", onKeyUp);
  }, [ visible ]);
  
  return createPortal(
    <div className={blockClasses.join(" ")}>
      <div className={`${BLOCK_NAME}__Overlay`} onClick={() => setVisible(false)}/>
      <PageContainer className={`${BLOCK_NAME}__Container`}>
        {children}
      </PageContainer>
    </div>,
    document.body
  );
}
