import "./_scss/Header.scss";
import { HTMLAttributes, ReactNode } from "react";

type HeaderProps = { children: ReactNode } & HTMLAttributes<HTMLDivElement>;

export function Header({ children, className, ...rest }: HeaderProps)
{
  const BLOCK_NAME = "Header";
  return (
    <div className={`${BLOCK_NAME} ${className}`} {...rest}>
      {children}
    </div>
  );
}
