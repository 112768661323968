import { HTMLAttributes, ReactNode } from "react";
import "./_scss/ProfileCard.scss";

type ProfileCardProps = {
  imgSrc: string;
  title: ReactNode;
  subtitle: ReactNode;
  description: ReactNode;
} & HTMLAttributes<HTMLDivElement>

export function ProfileCard({ imgSrc, title, subtitle, description, className, ...rest }: ProfileCardProps)
{
  const BLOCK_NAME = "ProfileCard";
  return (
    <div className={`${BLOCK_NAME} ${className}`} {...rest}>
      <div
        className={`${BLOCK_NAME}__Image`}
        style={{
          backgroundImage: `url(${imgSrc})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
      />
      <div className={`${BLOCK_NAME}__Details`}>
        <h5 className={`${BLOCK_NAME}__Title`}>{title}</h5>
        <h6 className={`${BLOCK_NAME}__Subtitle`}>{subtitle}</h6>
        {
          typeof description === "string"
            ? <p className={`${BLOCK_NAME}__Content`}>{description}</p>
            : <div className={`${BLOCK_NAME}__Content`}>{description}</div>
        }
      </div>
    </div>
  );
}
