import "./_scss/Meonbridge.scss";
import { Header } from "../Component/Header/Header";
import { Logo } from "../Component/Logo/Logo";
import { Hero } from "../Component/Hero/Hero";
import { PageContainer } from "../Component/PageContainer/PageContainer";
import { SectionHeader } from "../Component/SectionHeader/SectionHeader";
import { Section } from "../Component/Section/Section";
import { ThemeColorEnum } from "../Enum/ThemeColorEnum";
import { ProfileCard } from "../Component/ProfileCard/ProfileCard";
import { ContactCard } from "../Component/ContactCard/ContactCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { Button } from "../Component/Button/Button";
import { Navigation } from "../Component/Navigation/Navigation";
import { useState } from "react";
import { NavLink } from "../Component/NavLink/NavLink";
import { SectionEnum } from "../Enum/SectionEnum";
import { Grid } from "../Component/Grid/Grid";
import { FeatureCard } from "../Component/FeatureCard/FeatureCard";
import { useMediaQuery } from "../Hook/UseMediaQuery";
import { BreakpointEnum } from "../Enum/BreakpointEnum";
import { faScaleBalanced } from "@fortawesome/free-solid-svg-icons/faScaleBalanced";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons/faGraduationCap";
import { faHandshakeSimple } from "@fortawesome/free-solid-svg-icons/faHandshakeSimple";
import { faWrench } from "@fortawesome/free-solid-svg-icons/faWrench";
import { faChartColumn } from "@fortawesome/free-solid-svg-icons/faChartColumn";
import { faCircleNodes } from "@fortawesome/free-solid-svg-icons/faCircleNodes";
import { ServiceSummaryCard } from "../Component/ServiceSummaryCard/ServiceCardSummary";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { Modal } from "../Component/Modal/Modal";
import { ServiceCardModalContent } from "../Component/ServiceCardModalContent/ServiceCardModalContent";
import { faCompass } from "@fortawesome/free-solid-svg-icons/faCompass";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons/faPeopleGroup";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faComments } from "@fortawesome/free-solid-svg-icons/faComments";
import { faCoins } from "@fortawesome/free-solid-svg-icons/faCoins";
import { faRocket } from "@fortawesome/free-solid-svg-icons/faRocket";
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons/faHandHoldingDollar";
import { faChartPie } from "@fortawesome/free-solid-svg-icons/faChartPie";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass";
import { Footer } from "../Component/Footer/Footer";

const lightbulb = require("./_img/lightbulb.jpg");
const jetLunch = require("./_img/jet-lunch.jpg");
const profile = require("./_img/profile.jpg");

function scrollToSection(sectionId: string)
{
  const section = document.getElementById(sectionId);
  if (!section) return;
  section.scrollIntoView({ behavior: "smooth" });
}

export function Meonbridge()
{
  const BLOCK_NAME = "Meonbridge";
  const [ navVisible, setNavVisible ] = useState(false);
  const isMediumUp = useMediaQuery({ minWidth: BreakpointEnum.MEDIUM });
  const isXLargeUp = useMediaQuery({ minWidth: BreakpointEnum.XLARGE });
  const featureColumns = isXLargeUp ? 3 : isMediumUp ? 2 : 1;
  
  const [ sellingYourBusinessModalVisible, setSellingYourBusinessModalVisible ] = useState(false);
  const [ managingFamilyWealthModalVisible, setManagingFamilyWealthModalVisible ] = useState(false);
  
  const sellingYourBusinessModal = <Modal visible={sellingYourBusinessModalVisible}
                                          setVisible={setSellingYourBusinessModalVisible}>
    <ServiceCardModalContent
      onBackClick={() => setSellingYourBusinessModalVisible(false)}
      imageSrc={lightbulb}
      title={"Selling Your Business"}
      description={
        <>
          <p>
            Selling your business is a deeply personal decision, and recognising the emotional impact is essential. We
            help you prepare by providing insights from many years of experience helping clients navigate this path and
            sharing others’ experiences.
          </p>
          
          <p>
            We uncover the non-financial factors that matter most to you; your values, relationships, and ‘non
            negotiables’, which must be reflected in the decisions you make.
          </p>
          
          <p>
            Whether it’s protecting your family’s interests, achieving peace of mind or preserving your legacy, we
            ensure every aspect is dovetailed with the professional negotiations. We work with your advisers to ensure
            that everyone shares the same vision to produce a smoother process, and enable you to embrace the
            opportunities ahead with confidence.
          </p>
        </>
      }
      features={
        <Grid columns={featureColumns}>
          <FeatureCard
            icon={<FontAwesomeIcon icon={faCompass}/>}
            title={"Prepare, Prepare, Prepare"}
            description={"Is your business appealing to a purchaser? A future worth investing in, no surprises and likely progress without the founder at the helm. How does it feel to be superfluous?"}
          />
          <FeatureCard
            icon={<FontAwesomeIcon icon={faScaleBalanced}/>}
            title={"Balance Profit & Purpose"}
            description={"How should your values, relationships, and priorities impact negotiations? How do you balance financial and personal goals? Consider the views of family and stakeholders."}
          />
          <FeatureCard
            icon={<FontAwesomeIcon icon={faPeopleGroup}/>}
            title={"Assemble Your Team"}
            description={"What professional advisers do you need and when to ensure you’re properly supported throughout. Clear personal goals will help align your finance, tax and legal teams."}
          />
          <FeatureCard
            icon={<FontAwesomeIcon icon={faUsers}/>}
            title={"Shape Your Legacy"}
            description={"Who should own the proceeds? Is this capital intended to support one generation or many? What story will your great grandchildren tell about your efforts in creating this wealth?"}
          />
          <FeatureCard
            icon={<FontAwesomeIcon icon={faComments}/>}
            title={"Strengthen Family Dialogue"}
            description={"What do we say to our children and what are they saying to each other? Explore how and when to have meaningful conversations with your family about what the future holds."}
          />
          <FeatureCard
            icon={<FontAwesomeIcon icon={faCoins}/>}
            title={"Pots of Money"}
            description={"What does life look like post sale? How will you divide the proceeds to support your future plans whilst maintaining your family's financial security in the long term?"}
          />
        </Grid>
      }
    />
  </Modal>;
  
  const managingFamilyWealthModal = <Modal visible={managingFamilyWealthModalVisible}
                                           setVisible={setManagingFamilyWealthModalVisible}>
    <ServiceCardModalContent
      onBackClick={() => setManagingFamilyWealthModalVisible(false)}
      imageSrc={jetLunch}
      title={"Managing Family Wealth"}
      description={
        <>
          <p>
            Planning for the future often involves dividing wealth into categories that reflect your values and goals:
          </p>
          
          <ul>
            <li>
              <b>Family money:</b> Capital to support you, your children, and future generations.
            </li>
            <li>
              <b>Passion assets:</b> Investments in art, property, or items that bring joy.
            </li>
            <li>
              <b>Philanthropy:</b> Charitable structures to support the causes that matter to you and teach
              responsibility.
            </li>
            <li>
              <b>Private investments:</b> Opportunities to mentor entrepreneurs and earn returns.
            </li>
          </ul>
          
          <p>
            Through a deep understanding of what’s important to you and your family and how individual priorities shift
            over time, we help you design a plan that aligns with your objectives. Whether working with your current
            advisers or helping you find new ones, we ensure your wealth supports both your goals and values.
          </p>
        
        </>
      }
      features={
        <Grid columns={featureColumns}>
          <FeatureCard
            icon={<FontAwesomeIcon icon={faMagnifyingGlass}/>}
            title={"Find The Right Advisor"}
            description={"What advice do you need? How do you assess advisor performance and ensure costs align with value? Great service, performance, and value should be celebrated and preserved."}
          />
          <FeatureCard
            icon={<FontAwesomeIcon icon={faGraduationCap}/>}
            title={"Empower The Next Generation"}
            description={"How do you introduce your children to managing wealth and prepare them for inheritance? Most family difficulties trace back to a conversation that didn’t happen."}
          />
          <FeatureCard
            icon={<FontAwesomeIcon icon={faChartPie}/>}
            title={"Maintain the 'Blue Book'"}
            description={"How do you establish monitoring systems that provide simplicity, clarity, and efficiency? If the worst happened, would those that matter know what there is and how to take control?"}
          />
          <FeatureCard
            icon={<FontAwesomeIcon icon={faHandHoldingDollar}/>}
            title={"Philanthropy With Purpose"}
            description={"How can you best support good causes that are meaningful to you and your family? Do the next generation share your values, priorities, beliefs, and charitable aspirations?"}
          />
          <FeatureCard
            icon={<FontAwesomeIcon icon={faRocket}/>}
            title={"Invest With Impact"}
            description={"What opportunities are there to use your experience to mentor entrepreneurs, make impactful investments, foster innovation or connect with social enterprises?"}
          />
          <FeatureCard
            icon={<FontAwesomeIcon icon={faCoins}/>}
            title={"Family Governance"}
            description={"How can you successfully plan for unexpected future family challenges and maintain harmony when difficulties arise? Should you explore a family constitution?"}
          />
        </Grid>
      }
    />
  </Modal>;
  
  return (
    <div className={BLOCK_NAME}>
      <Section
        backgroundColor={ThemeColorEnum.GREEN}
        className={`${BLOCK_NAME}__HeroSection`}
      >
        <PageContainer className={`${BLOCK_NAME}__Header`}>
          <Header>
            <Logo/>
            <Navigation visible={navVisible} setVisible={setNavVisible}>
              <NavLink
                onClick={() => {
                  scrollToSection(SectionEnum.WHAT_WE_DO);
                  setNavVisible(false);
                }}
              >
                What we do
              </NavLink>
              <NavLink
                onClick={() => {
                  scrollToSection(SectionEnum.WHO_WE_ARE);
                  setNavVisible(false);
                }}
              >
                Who we are
              </NavLink>
              <Button
                color={ThemeColorEnum.BEIGE}
                onClick={() => {
                  scrollToSection(SectionEnum.LETS_TALK);
                  setNavVisible(false);
                }}
              >
                Book a Call
              </Button>
            </Navigation>
          </Header>
        </PageContainer>
        <Hero title={<>Wealth should be a privilege,<br/> not a burden.</>}></Hero>
      </Section>
      
      <Section backgroundColor={ThemeColorEnum.BEIGE} id={SectionEnum.WHAT_WE_DO}>
        <PageContainer>
          <SectionHeader>What we do.</SectionHeader>
          
          <div className={`${BLOCK_NAME}__ServicesSummaryContainer`}>
            <ServiceSummaryCard
              imageSrc={lightbulb}
              title={"Selling Your Business"}
              description={
                <>
                  <p>We guide shareholders through the emotional and practical challenges of selling their business,
                    ensuring their values and personal goals align for a smooth transition and a future they have
                    designed rather than drifted into.</p>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      color={ThemeColorEnum.NAVY}
                      onClick={() => setSellingYourBusinessModalVisible(true)}
                    >
                      Learn More <FontAwesomeIcon style={{ marginLeft: 8 }} icon={faPlus}/>
                    </Button>
                  </div>
                </>
              }
            />
            {sellingYourBusinessModal}
            
            
            <ServiceSummaryCard
              imageSrc={jetLunch}
              title={"Managing Family Wealth"}
              description={
                <>
                  <p>We coach families through the division of capital to different purposes, guide the selection and
                    monitoring of advisers and provide consolidated reporting for clarity, efficiency, peace of mind and
                    effective succession planning.</p>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      color={ThemeColorEnum.NAVY}
                      onClick={() => setManagingFamilyWealthModalVisible(true)}
                    >
                      Learn More <FontAwesomeIcon style={{ marginLeft: 8 }} icon={faPlus}/>
                    </Button>
                  </div>
                </>
              }
            />
          </div>
          {managingFamilyWealthModal}
        
        </PageContainer>
      </Section>
      
      <Section backgroundColor={ThemeColorEnum.GREEN} id={SectionEnum.WHO_WE_ARE}>
        <PageContainer>
          <SectionHeader>Who we are.</SectionHeader>
          
          <ProfileCard
            imgSrc={profile}
            title={"Peter Burke, Chartered FCSI"}
            subtitle={"Founder & Managing Director"}
            description={
              <>
                <p>
                  Originally qualified as a professional Executor and Corporate Trustee, Peter spent 38 years with the
                  Private Banking arms of several UK Banks in various parts of the UK. He has specialised in looking
                  after the needs of former business owners and their families for the last 20 years as a level 6
                  qualified regulated adviser.
                </p>
                <p>
                  No longer offering regulated financial advice but instead choosing to augment the work of the best
                  advisers and investment managers in the market, he helps families develop their knowledge and
                  understanding of what’s available, identify and monitor professional advisers and investment managers
                  where needed and facilitate succession plans to ensure that family wealth is a privilege and not a
                  burden.
                </p>
              </>
            }
            className={`${BLOCK_NAME}__ProfileCard`}
          />
          
          <Grid columns={featureColumns}>
            <FeatureCard
              icon={<FontAwesomeIcon icon={faScaleBalanced}/>}
              title={"Independent"}
              description={"We do not offer regulated financial advice which leaves us free to offer entirely unbiased guidance. We help clients objectively evaluate their existing advisers and plans."}
            />
            <FeatureCard
              icon={<FontAwesomeIcon icon={faGraduationCap}/>}
              title={"Unrivalled Experience"}
              description={"Factors beyond the balance sheet usually have the biggest impact on families. Quality financial advice is important; we supplement this with a deeper understanding of the bigger picture."}
            />
            <FeatureCard
              icon={<FontAwesomeIcon icon={faHandshakeSimple}/>}
              title={"Client Focused"}
              description={"We combine the complementary disciplines of coaching, mentoring, education and consulting to design a bespoke package of services around the individual needs of each family member."}
            />
            <FeatureCard
              icon={<FontAwesomeIcon icon={faWrench}/>}
              title={"Flexible"}
              description={"Our fees are contract dependant, and based on the time and expertise employed rather than arbitrary asset values. An estimate is agreed between us before any commitment is made."}
            />
            <FeatureCard
              icon={<FontAwesomeIcon icon={faChartColumn}/>}
              title={"Cashflow Modelling"}
              description={"Whilst cashflow modelling in financial planning is very useful, many tools do not effectively illustrate the impact of variable returns. We use proprietary software to add colour to the projections made."}
            />
            <FeatureCard
              icon={<FontAwesomeIcon icon={faCircleNodes}/>}
              title={"Connected"}
              description={"We maintain excellent relationships with Corporate Finance teams, investment managers, financial advisers, lawyers and accountants.  We recognise quality when we see it."}
            />
          </Grid>
        
        </PageContainer>
      </Section>
      
      <Section backgroundColor={ThemeColorEnum.BEIGE} id={SectionEnum.LETS_TALK}>
        <PageContainer>
          <SectionHeader>Let's talk.</SectionHeader>
          
          <div className={`${BLOCK_NAME}__ContactContainer`}>
            
            <div className={`${BLOCK_NAME}__ContactContainerText`}>
              <p>Whatever your needs, we'd love to hear from you. Our process starts with an
                informal discovery call to get to know your personal circumstances and help you understand where we can
                help.</p>
            </div>
            
            <div className={`${BLOCK_NAME}__ContactContainerDetails`}>
              <a href={"mailto:peter@meonbridge.com"}>
                <ContactCard
                  icon={<FontAwesomeIcon icon={faEnvelope}/>}
                  title={"peter@meonbridge.com"}
                />
              </a>
              
              <a href={"tel:+447966057066"}>
                <ContactCard
                  icon={<FontAwesomeIcon icon={faPhone}/>}
                  title={"+44 (0) 7966 057066"}
                />
              </a>
              
              <a href={"https://www.linkedin.com/in/peter-burke-uk"} target={"_blank"}>
                <ContactCard
                  icon={<FontAwesomeIcon icon={faLinkedinIn}/>}
                  title={"linkedin.com/in/peter-burke-uk"}
                />
              </a>
            </div>
          
          </div>
        
        </PageContainer>
      </Section>
      
      <Footer/>
    
    </div>
  );
}
