import { ReactNode } from "react";
import "./_scss/Grid.scss";

interface GridProps
{
  columns: number;
  children: ReactNode;
}

export function Grid({ columns, children }: GridProps)
{
  const BLOCK_NAME = "Grid";
  return (
    <div className={BLOCK_NAME} style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
      {children}
    </div>
  );
}
