import "./_scss/Hero.scss";
import { ReactNode } from "react";

interface HeroProps
{
  title: ReactNode;
}

export function Hero({ title }: HeroProps)
{
  const BLOCK_NAME = "Hero";
  return (
    <div className={BLOCK_NAME}>
      <h1 className={`${BLOCK_NAME}__Title`}>{title}</h1>
    </div>
  );
}
