import { Logo } from "../Logo/Logo";
import { PageContainer } from "../PageContainer/PageContainer";
import "./_scss/Footer.scss";
import { useMemo } from "react";

export function Footer()
{
  const BLOCK_NAME = "Footer";
  const year = useMemo(() => (new Date()).getFullYear(), []);
  
  return (
    <div className={BLOCK_NAME}>
      <PageContainer className={`${BLOCK_NAME}__Container`}>
        <p className={`${BLOCK_NAME}__Logo`}>
          <Logo/>
        </p>
        <p className={`${BLOCK_NAME}__Copyright`}>
          © {year} Meonbridge Limited
        </p>
      </PageContainer>
      
    </div>
  )
}
