import { ReactNode } from "react";
import "./_scss/NavLink.scss";

interface NavLinkProps
{
  onClick: () => void;
  children: ReactNode;
}

export function NavLink({ onClick, children }: NavLinkProps)
{
  const BLOCK_NAME = "NavLink";
  return (
    <a
      className={BLOCK_NAME}
      href={"#"}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      {children}
    </a>
  );
}
