import { ReactNode } from "react";
import "./_scss/ServiceSummaryCard.scss";

interface ServiceSummaryCardProps
{
  imageSrc: string;
  title: ReactNode;
  description: ReactNode;
}

export function ServiceSummaryCard({ imageSrc, title, description }: ServiceSummaryCardProps)
{
  const BLOCK_NAME = "ServiceSummaryCard";
  
  return (
    <div className={BLOCK_NAME}>
      <div
        className={`${BLOCK_NAME}__Container`}
      >
        <div
          className={`${BLOCK_NAME}__Image`}
          style={{
            backgroundImage: `url(${imageSrc})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
          }}
        />
        <div className={`${BLOCK_NAME}__Content`}>
          <h3 className={`${BLOCK_NAME}__Title`}>
            {title}
          </h3>
          <div className={`${BLOCK_NAME}__Description`}>
            {description}
          </div>
        </div>
      </div>
    </div>
  );
}
