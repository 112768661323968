import { useEffect, useState } from "react";

export function useMediaQuery(options: { minWidth?: number | string, maxWidth?: number | string })
{
  const { minWidth, maxWidth } = options;
  const conditions = [
    minWidth != null && `min-width: ${minWidth}${typeof minWidth === "number" ? "px" : ""}`,
    maxWidth != null && `max-width: ${maxWidth}${typeof maxWidth === "number" ? "px" : ""}`
  ];
  const mediaQuery = `(${conditions.filter(Boolean).join(" and ")})`;
  const [ matches, setMatches ] = useState(window.matchMedia(mediaQuery).matches);
  
  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    const listener = (event: MediaQueryListEvent) => setMatches(event.matches);
    mediaQueryList.addEventListener("change", listener);
    return () => {
      mediaQueryList.removeEventListener("change", listener);
    };
  }, [ mediaQuery, setMatches ]);
  
  return matches;
}
