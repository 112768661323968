import { ReactNode } from "react";
import "./_scss/SectionHeader.scss";
import { ThemeColorEnum } from "../../Enum/ThemeColorEnum";

interface SectionHeaderProps
{
  color?: ThemeColorEnum;
  children: ReactNode;
}

export function SectionHeader({ color, children }: SectionHeaderProps)
{
  const BLOCK_NAME = "SectionHeader";
  const blockClasses = [ BLOCK_NAME ];
  if (color) blockClasses.push(`${BLOCK_NAME}--${color}`);
  return (
    <h2 className={blockClasses.join(" ")}>
      {children}
    </h2>
  );
}
