import { ReactNode } from "react";
import "./_scss/ServiceCardModalContent.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { ModalContent } from "../ModalContent/ModalContent";
import { useMediaQuery } from "../../Hook/UseMediaQuery";
import { BreakpointEnum } from "../../Enum/BreakpointEnum";

interface ServiceCardModalContentProps
{
  imageSrc: string;
  title: ReactNode;
  description: ReactNode;
  features: ReactNode;
  onBackClick: () => void;
}

export function ServiceCardModalContent({
  imageSrc,
  title,
  description,
  features,
  onBackClick
}: ServiceCardModalContentProps)
{
  const BLOCK_NAME = "ServiceCardModalContent";
  const isMediumUp = useMediaQuery({ minWidth: BreakpointEnum.MEDIUM });
  return (
    <div className={BLOCK_NAME}>
      <ModalContent spacing={{ all: true, bottom: isMediumUp ? 35 : true }} className={`${BLOCK_NAME}__TitleSection`}>
        <div className={`${BLOCK_NAME}__TopBar`}>
          <div className={`${BLOCK_NAME}__BackButton`} onClick={onBackClick}>
            <FontAwesomeIcon icon={faArrowLeft}/>
          </div>
          <h3 className={`${BLOCK_NAME}__Title`}>
            {title}
          </h3>
        </div>
      </ModalContent>
      <ModalContent spacing={{ all: true }} scrollable={true}>
        <div className={`${BLOCK_NAME}__ContentContainer`}>
          <div
            className={`${BLOCK_NAME}__Image`}
            style={{
              backgroundImage: `url(${imageSrc})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat"
            }}
          />
          <div>
            <div className={`${BLOCK_NAME}__Description`}>
              {description}
            </div>
          </div>
        </div>
        <div className={`${BLOCK_NAME}__Features`}>{features}</div>
      </ModalContent>
    
    </div>
  );
}
