import { ReactNode } from "react";
import "./_scss/ContactCard.scss";

interface ContactCardProps
{
  icon: ReactNode,
  title: ReactNode
}

export function ContactCard({ icon, title }: ContactCardProps)
{
  const BLOCK_NAME = "ContactCard";
  
  return (
    <div className={BLOCK_NAME}>
      <div className={`${BLOCK_NAME}__IconContainer`}>{icon}</div>
      {
        typeof title === "string"
          ? <h6 className={`${BLOCK_NAME}__TitleContainer`}>{title}</h6>
          : <div className={`${BLOCK_NAME}__TitleContainer`}>{title}</div>
      }
    </div>
  );
}
