import { ReactNode } from "react";
import "./_scss/FeatureCard.scss";

interface FeatureCardProps
{
  icon: ReactNode;
  title: string;
  description: string;
}

export function FeatureCard({ icon, title, description }: FeatureCardProps)
{
  const BLOCK_NAME = "FeatureCard";
  return (
    <div className={BLOCK_NAME}>
      <div className={`${BLOCK_NAME}__TitleContainer`}>
        <div className={`${BLOCK_NAME}__Icon`}>
          {icon}
        </div>
        <h3 className={`${BLOCK_NAME}__Title`}>
          {title}
        </h3>
      </div>
      <p className={`${BLOCK_NAME}__Description`}>
        {description}
      </p>
    </div>
  );
}
