import { forwardRef, HTMLAttributes } from "react";
import "./_scss/PageContainer.scss";

type PageContainerProps = HTMLAttributes<HTMLDivElement>;

export const PageContainer = forwardRef<HTMLDivElement, PageContainerProps>(
  function PageContainer({ children, className = "", ...rest }: PageContainerProps, ref) {
    const BLOCK_NAME = "PageContainer";
    return (
      <div className={`${BLOCK_NAME} ${className}`} {...rest} ref={ref}>
        {children}
      </div>
    );
  }
);

