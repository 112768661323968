import "./_scss/Logo.scss";
const logo = require("./_img/meonbridge.svg");

export function Logo()
{
  const BLOCK_NAME = "Logo";
  return (
    <div className={BLOCK_NAME}>
      <img src={logo} alt="Meonbridge Logo"/>
    </div>
  );
}
