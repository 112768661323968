import { HTMLAttributes, ReactNode } from "react";
import "./_scss/Button.scss";
import { ThemeColorEnum } from "../../Enum/ThemeColorEnum";
import { ButtonSizeEnum } from "./Enum/ButtonSizeEnum";

type ButtonProps = {
  color: ThemeColorEnum,
  size?: ButtonSizeEnum,
  children: ReactNode
} & HTMLAttributes<HTMLAnchorElement>

export function Button({ color, size, children, className, ...rest }: ButtonProps)
{
  const BLOCK_NAME = "Button";
  const blockClasses = [ BLOCK_NAME, `${BLOCK_NAME}--${color}` ];
  if (className) blockClasses.push(className);
  blockClasses.push(`${BLOCK_NAME}--${size || ButtonSizeEnum.MEDIUM}`);
  return <a className={blockClasses.join(" ")} {...rest}>{children}</a>;
}
