import { HTMLAttributes, ReactNode } from "react";
import "./_scss/Section.scss";
import { ThemeColorEnum } from "../../Enum/ThemeColorEnum";

type SectionProps = {
  backgroundColor: ThemeColorEnum;
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>

export function Section({ backgroundColor, children, className, ...props }: SectionProps)
{
  const BLOCK_NAME = "Section";
  const blockClasses = [ BLOCK_NAME ];
  if (backgroundColor) blockClasses.push(`${BLOCK_NAME}--${backgroundColor}`);
  if (className) blockClasses.push(className);
  
  return (
    <div className={blockClasses.join(" ")} {...props}>
      {children}
    </div>
  );
}
