import { ReactNode } from "react";
import "./_scss/Navigation.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { useMediaQuery } from "../../Hook/UseMediaQuery";
import { BreakpointEnum } from "../../Enum/BreakpointEnum";
import { createPortal } from "react-dom";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";

interface NavigationProps
{
  visible: boolean,
  setVisible: (visible: boolean) => void,
  children: ReactNode
}

export function Navigation({ visible, setVisible, children }: NavigationProps)
{
  const BLOCK_NAME = "Navigation";
  const blockClasses = [ BLOCK_NAME ];
  const isLargeUp = useMediaQuery({ minWidth: BreakpointEnum.LARGE });
  if (visible || isLargeUp) blockClasses.push(`${BLOCK_NAME}--Visible`);
  
  
  const nav = (
    <nav className={blockClasses.join(" ")}>
      <div className={`${BLOCK_NAME}__CloseIcon`} onClick={() => setVisible(false)}>
        <FontAwesomeIcon icon={faTimes}/>
      </div>
      {children}
    </nav>
  );
  
  return (
    <>
      {!isLargeUp && createPortal(nav, document.body)}
      {isLargeUp ? nav :
        <div className={`${BLOCK_NAME}MenuIcon`} onClick={() => setVisible(true)}><FontAwesomeIcon icon={faBars}/>
        </div>}
    </>
  );
}
